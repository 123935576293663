import http from '@/utils/http';

/**
 * @typedef Agent
 *  @property {number} id
 *  @property {string} name
 */

/**
 * @typedef Profit
 *  @property {number} deposit
 *  @property {number} withdrawal
 *  @property {number} total
 */

/**
 * @typedef MainInfo
 *  @property {Agent} agent
 *  @property {number} balance
 *  @property {number} limit
 *  @property {number} remain_limit
 *  @property {number} blockDeposit
 *  @property {number} minLimit
 *  @property {number} bk_balance
 *  @property {string} currency
 *  @property {Profit} profit
 *  @property {boolean} haveSubagents
 */

/**
 * @return {Promise<{ data: MainInfo }>}
 */
export const getMainInfo = () => http.post('/main');
