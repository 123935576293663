const resource = {
  "confirm": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a deposit"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to make a cashbox deposit?"])}
  },
  "error": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact your manager"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit not available"])}
  },
  "modal": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter deposit amount"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashbox deposit"])}
  },
  "success": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashbox deposit is made"])}
  }
}
export default resource