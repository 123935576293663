const resource = {
  "availableBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available amount"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "currentProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available to withdraw"])},
  "currentProfitCashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for withdrawal from the cash register"])},
  "currentProfitMaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for withdrawal from subagents"])},
  "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee"])},
  "fromDeposits": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From deposits fee ", _interpolate(_named("percent"))])},
  "fromWithdrawals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From withdrawals fee ", _interpolate(_named("percent"))])},
  "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "paymentRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment request"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
  "profitIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Profit in ", _interpolate(_named("month"))])},
  "refillCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a cashbox deposit"])},
  "sumProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total profit"])},
  "topUpAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top up account"])},
  "totalProfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total profit"])}
}
export default resource