const resource = {
  "autoWithdrawal": {
    "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-withdrawal deactivated"])},
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-withdrawal activated"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "createWithdrawal": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal request submitted"])}
  },
  "errors": {
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount requested exceeds your current income"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum withdrawal amount — ", _interpolate(_named("min"))])}
  },
  "historyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal history"])},
  "info": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal history"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of withdrawals"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal information"])},
    "totalSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total withdrawn"])}
  },
  "tabs": {
    "cashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash register"])},
    "master": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subagents"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent withdrawals"])},
  "withdrawalTextInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["*Withdrawals are available every Wednesday. After moderation, the wins will be credited into your account ", _interpolate(_named("email"))])},
  "withdrawals": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player account"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal request"])}
  }
}
export default resource