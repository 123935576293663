import agentWithdrawals from './agentWithdrawals.json';
import details from './details.json';
import errors from './errors.json';
import headerContent from './headerContent.json';
import history from './history.json';
import home from './home.json';
import login from './login.json';
import modals from './modals.json';
import navigation from './navigation.json';
import payments from './payments.json';
import refill from './refill.json';
import refillCash from './refillCash.json';
import rules from './rules.json';
import search from './search.json';
import statistics from './statistics.json';
import technicalWorks from './technicalWorks.json';

export default {
  headerContent,
  navigation,
  login,
  modals,
  home,
  refill,
  search,
  payments,
  details,
  history,
  errors,
  statistics,
  agentWithdrawals,
  rules,
  technicalWorks,
  refillCash,
};
